import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static get targets() {
    return ["input", "onIcon", "offIcon"]
  }

  get isExposed() {
    return this.inputTarget.type === "text"
  }

  toggle() {
    const currentlyExposed = this.isExposed
    this.inputTarget.type = currentlyExposed ? "password": "text"
    this.onIconTarget.hidden = currentlyExposed
    this.offIconTarget.hidden = !currentlyExposed
  }
}
